import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
// import Navbar from '../components/Navbar'
import {
    BrowserRouter as Router,
    Link,
    Switch
  } from "react-router-dom";
import { RouteWithSubRoutes } from '../router/router'
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import EditIcon from '@material-ui/icons/Edit';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import APIS from '../api/api';
import { Person } from '@material-ui/icons';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AddIcon from '@material-ui/icons/Add';
import { liveURL } from '../api/axios';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const Home = ({ routes }) => {
    const history = useHistory()
    const [loading , setLoading] = useState(false)
    const classes = useStyles();
    const theme = useTheme();
    const [open, setOpen] = useState(false);
    const [code , setCode] = useState('')
    const [error , setError] = useState('')
    const [success , setSuccess] = useState('')
    const [profile , setProfile] = useState('')

    const handleDrawerOpen = () => {
        setOpen(true);
      };
    
      const handleDrawerClose = () => {
        setOpen(false);
      };
    
      const JoinCourseAPI = async () => {
          const formData = new FormData()
          formData.append('code' , code)
          await APIS.joinCourse(formData).then(res => {
              setError('')
              setSuccess('Class joined successfully')
              setCode('')
              setTimeout(() => {
                window.location.reload()
              } , 3000)
          }).catch(err => {
            setSuccess('')
            setError(err.response.data.message)
          })
      }

    useEffect(() => {
        if(!localStorage.getItem('token')){
            history.push('/login')
        }
        else{
          const myProfileAPI = async () => {
            await APIS.myProfile().then(res => setProfile(res.data))
          }
          myProfileAPI()
            setLoading(true)
        }
    } , [history])
    return (
        loading ?
<Router>
        <div className={classes.root}>
    <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="staticBackdropLabel">Join Course</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => {
            setSuccess('')
            setError('')
            setCode('')
            }}></button>
      </div>
      <div className="modal-body">
          <h4>Course Code</h4>
        <input type="text" className="form-control" value={code} onChange={(e) => setCode(e.target.value)} />
        <br></br>
        {success ? <div className="alert-success" style={{ padding : 15 , fontWeight : 'bold' , borderRadius : 20 }}>{success}</div> : <></>}
        {error ? <div className="alert-danger" style={{ padding : 15 , fontWeight : 'bold' , borderRadius : 20 }}>{error}</div> : <></>}
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={(e) => {
            setSuccess('')
            setError('')
            setCode('')
            }}>Close</button>
        <button type="button" className="btn btn-primary" onClick={(e) => JoinCourseAPI()}>Join</button>
      </div>
    </div>
  </div>
</div>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        style={{ backgroundColor : 'red' }}
        >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            Online Quiz Maker for Teachers
          </Typography>
          <Typography variant="h6" noWrap style={{ marginLeft: "auto" , display : 'flex' , alignItems : 'center' }}>
            {profile.avatar ? <span><img alt="profileim" style={{ width : 55 , height : 55 , borderRadius : '50%' , marginRight : 10 }} src={`${liveURL}${profile.avatar}`} /></span> : <></>}
            <span style={{ marginRight : 10 }}>{profile.username}</span>
            <ExitToAppIcon onClick={(e) => {
              localStorage.clear()
              window.location.reload()
            }} style={{ cursor : 'pointer' }} />
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {['Join Class' , 'Courses' , 'Profile' , 'Add Doctor to Course' , 'Add Course' , 'Add Users'].map((text, index) => (
            <div key={index}>
              {
              index === 0 && Number(profile.user_type_id) === 0 ?
            <ListItem button key={text} data-bs-toggle="modal" data-bs-target="#staticBackdrop">
              <ListItemIcon><EditIcon /></ListItemIcon>
              <ListItemText primary={text} style={{ color : 'black' }} />
            </ListItem>
            :
            index === 1 && Number(profile.user_type_id) !== 2 ?
            <Link to="/">
            <ListItem button key={text}>
              <ListItemIcon><LocalLibraryIcon /></ListItemIcon>
              <ListItemText primary={text} style={{ color : 'black' }} />
            </ListItem>
            </Link>
            :
            index === 2 ?
            <Link to="/info">
            <ListItem button key={text}>
              <ListItemIcon><Person /></ListItemIcon>
              <ListItemText primary={text} style={{ color : 'black' }} />
            </ListItem>
            </Link>
            :
            index === 3 && Number(profile.user_type_id) === 2 ?
            <Link to="/addDoctorToCourse">
            <ListItem button key={text}>
              <ListItemIcon><AddIcon /></ListItemIcon>
              <ListItemText primary={text} style={{ color : 'black' }} />
            </ListItem>
            </Link>
            :
            index === 4 && Number(profile.user_type_id) === 2 ?
            <Link to="/addCourse">
            <ListItem button key={text}>
              <ListItemIcon><AddIcon /></ListItemIcon>
              <ListItemText primary={text} style={{ color : 'black' }} />
            </ListItem>
            </Link>
             :
             index === 5 && Number(profile.user_type_id) === 2 ?
             <Link to="/addUsers">
             <ListItem button key={text}>
               <ListItemIcon><AddIcon /></ListItemIcon>
               <ListItemText primary={text} style={{ color : 'black' }} />
             </ListItem>
             </Link>
            :
            <></>
              }
            </div>
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>
      <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
      </main>
    </div>
    </Router>
        :
        <></>
    )
}

export default Home
