import instance from './axios'

const APIS = {
    login : (data) => {
        return instance.post('/login' , data)
    },
    register : (data) => {
        return instance.post('/register' , data)
    },
    joinCourse : (data) => {
        return instance.post('/joinCourse' , data , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    myCourses : () => {
        return instance.get('/myCourses' , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    getDoctors : () => {
        return instance.get('/getDoctors' , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    myLectures : (title) => {
        return instance.get(`/myLectures/${title}` , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    courses : () => {
        return instance.get(`/course` , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    updateCode : (id) => {
        return instance.put(`/updateCode/${id}` , {} , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    cheating : (quiz_id) => {
        return instance.put(`/cheating/${quiz_id}` , {} , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    AddToCourse : (courseId , data) => {
        return instance.post(`/AddToCourse/${courseId}` , data , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    addCourse : (data) => {
        return instance.post(`/addCourse` , data , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    lectureQuestions : (courseTitle , lectureTitle) => {
        return instance.get(`/lectureQuestions/${courseTitle}/${lectureTitle}` , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    addQuestion : (data , lecture_name) => {
        return instance.post(`/question_bank/${lecture_name}` , data , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    deleteQuestion : (id) => {
        return instance.delete(`/question_bank/${id}` , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    updateQuestion : (id , data) => {
        return instance.put(`/question_bank/${id}` , data , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    importQuestions : (lecture_title , data) => {
        return instance.post(`/importQuestions/${lecture_title}` , data , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    importUsers : (data) => {
        return instance.post(`/importUsers` , data , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    myProfile : () => {
        return instance.get(`/myProfile` , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    updateProfile : (data) => {
        return instance.put(`/updateProfile` , data , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    updateLectureStatus : (courseTitle , id , data) => {
        return instance.put(`/updateLectureStatus/${courseTitle}/${id}` , data , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    doctorsCourse : (id) => {
        return instance.get(`/doctorsCourse/${id}` , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    createLecture : (course_title , data) => {
        return instance.post(`/createLecture/${course_title}` , data , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    generateQuiz : (data) => {
        return instance.post(`/generate_quiz` , data , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    deleteLecture : (id) => {
        return instance.delete(`/lecture/${id}` , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    upcomingQuizzes : (courseName) => {
        return instance.get(`/upcomingQuizzes/${courseName}` , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    missedQuizzes : (courseName) => {
        return instance.get(`/missedQuizzes/${courseName}` , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    doneQuizzes : (courseName) => {
        return instance.get(`/doneQuizzes/${courseName}` , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    runningQuizzes : (courseName) => {
        return instance.get(`/runningQuizzes/${courseName}` , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    showQuiz : (id) => {
        return instance.get(`/quiz/${id}` , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    storeResult : (data) => {
        return instance.post(`/storeResult` , data , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
    studentsAPI : () => {
        return instance.get(`/studentsAPI` , {
            headers : {
                'Authorization' : `Bearer ${localStorage.getItem("token")}`
            }
        })
    },
}

export default APIS