import React , { useState , useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import APIS from '../api/api';
import CoursesCard from '../components/CoursesCard';
import { useHistory } from 'react-router';


const useStyles = makeStyles((theme) => ({
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    }
  }));

const Courses = () => {
    const classes = useStyles();
    const [myCourses , setMyCourses] = useState([])
    const [error , setError] = useState()
    const [loadingPage , setLoadingPage] = useState(false)
    const [profile , setProfile] = useState({})

    const history = useHistory()

    useEffect(() => {
      const myProfileAPI = async () => {
        await APIS.myProfile().then(res => {
          setProfile(res.data)
          setLoadingPage(true)
        }).catch(err => history.push('/login'))
      }

      if(!localStorage.getItem('token')){
        history.push('/login')
    }
    else{
      myProfileAPI()
    }
      const coursesCardAPI = async () => {
        await APIS.myCourses().then(res => {
          console.log(res.data)
          setMyCourses(res.data)
        }).catch(err => {
          setError(err.response.data.message)
        })
      }
      coursesCardAPI()
    } , [history])
    return (
      loadingPage ?
        <div>

<div className={classes.toolbar} />
<div className="row">
  <h1 style={{ fontWeight : 'bold' }}>My Courses</h1>
{
  error ?
  <div className="row">
    <div className="alert-danger col-md-12" style={{ padding : 15 , marginTop : 10 , fontWeight : 'bold' }}>{error}</div>
    </div>
  :
          myCourses.map((value , key) => (
            <div className="col-md-3" style={{ marginTop : 10 ,  }} key={key}>
              <CoursesCard user_type_id={profile.user_type_id} course={value.courses} />
            </div>
          ))
        }
</div>
       
        </div>
        :
        <></>
    )
}

export default Courses
