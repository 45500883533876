import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router';

export default function SimpleCard({ user_type_id , course }) {
  console.log(course)
    const history = useHistory()
  return (
    user_type_id !== 2 ?
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2">
          {course[0].title}
        </Typography>
        <Typography variant="body2" component="p">
         {course[0].description}
        </Typography>
      </CardContent>
      <CardActions>
        {
        Number(user_type_id) !== 0 ? 
        <Button size="small" onClick={() => history.push(`/myCourse/${course[0].title}`)}>Enter Course</Button>
        :
        <Button size="small" onClick={() => history.push(`/myQuizzes/${course[0].title}`)}>Show Quizzes</Button>
        }
      </CardActions>
    </Card>
    :
    <Card>
      <CardContent>
        <Typography variant="h5" component="h2">
          {course.title}
        </Typography>
        <Typography variant="body2" component="p">
         {course.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => history.push(`/addStudents/${course.title}`)}>Add Students</Button>
      </CardActions>
    </Card>
  );
}
