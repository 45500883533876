import React , { useState , useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import APIS from '../api/api';
import { useHistory, useParams } from 'react-router';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import QuizzesCard from '../components/QuizzesCard'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    }
  }));

const MyQuizzes = () => {
    const classes = useStyles();
    const [myCourses , setMyCourses] = useState([])
    const [error , setError] = useState()
    const [loadingPage , setLoadingPage] = useState(false)
    const [profile , setProfile] = useState({})
    const theme = useTheme();
  const [value, setValue] = useState(0);
  const { title } = useParams()
  const [upcomingQuizzes , setUpcomingQuizzes] = useState([])
  const [upcomingLoading , setUpcomingLoading] = useState(false)
  const [doneQuizzes , setDoneQuizzes] = useState([])
  const [doneLoading , setDoneLoading] = useState(false)
  const [missedQuizzes , setMissedQuizzes] = useState([])
  const [missedLoading , setMissedLoading] = useState(false)
  const [runningQuizzes , setRunningQuizzes] = useState([])
  const [runningLoading , setRunningLoading] = useState(false)

    const history = useHistory()

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    };

    useEffect(() => {
      const upcomingAPI = async () => {
          await APIS.upcomingQuizzes(title).then(res => {
            setUpcomingQuizzes(res.data)
            setUpcomingLoading(true)
          }).catch(err => console.log(err.response.data.message))
      }
      upcomingAPI()
      const doneAPI = async () => {
        await APIS.doneQuizzes(title).then(res => {
          setDoneQuizzes(res.data)
          setDoneLoading(true)
        }).catch(err => console.log(err.response.data.message))
    }
    doneAPI()
    const missedAPI = async () => {
      await APIS.missedQuizzes(title).then(res => {
        setMissedQuizzes(res.data)
        setMissedLoading(true)
      }).catch(err => console.log(err.response.data.message))
  }
  missedAPI()
  const runningAPI = async () => {
    await APIS.runningQuizzes(title).then(res => {
      setRunningQuizzes(res.data)
      setRunningLoading(true)
    }).catch(err => console.log(err.response.data.message))
}
runningAPI()
    } , [])

    useEffect(() => {
      const myProfileAPI = async () => {
        await APIS.myProfile().then(res => {
          setProfile(res.data)
          setLoadingPage(true)
        }).catch(err => history.push('/login'))
      }

      if(!localStorage.getItem('token')){
        history.push('/login')
    }
    else{
      myProfileAPI()
    }
      const coursesCardAPI = async () => {
        await APIS.myCourses().then(res => {
          setMyCourses(res.data)
        }).catch(err => {
          setError(err.response.data.message)
        })
      }
      coursesCardAPI()
    } , [history])
    return (
      loadingPage ?
        <div>

<div className={classes.toolbar} />
<div className="row">
  <h1 style={{ fontWeight : 'bold' }}>({title}) Quizzes</h1>
{
  error ?
  <div className="row">
    <div className="alert-danger col-md-12" style={{ padding : 15 , marginTop : 10 , fontWeight : 'bold' }}>{error}</div>
    </div>
  :
  <div className={classes.root}>
    <br></br>
  <AppBar position="static" color="default">
    <Tabs
      value={value}
      onChange={handleChange}
      indicatorColor="primary"
      textColor="primary"
      variant="fullWidth"
      aria-label="full width tabs example"
    >
      <Tab label="Done Quizzes" {...a11yProps(0)} />
      <Tab label="Upcoming Quizzes" {...a11yProps(1)} />
      <Tab label="Running Quizzes" {...a11yProps(2)} />
      <Tab label="Missed Quizzes" {...a11yProps(3)} />
    </Tabs>
  </AppBar>
  <SwipeableViews
    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
    index={value}
    onChangeIndex={handleChangeIndex}
  >
    <TabPanel value={value} index={0} dir={theme.direction}>
      <div className="row">
      {
        doneLoading ?
        doneQuizzes.length > 0 ?
        doneQuizzes.map((value , key) => (
          <div className="col col-md-4" key={key} style={{ marginBottom : 15 }}>
          <QuizzesCard caseCheck="#33cc38" quiz={value} />
          </div>
        ))
        :
        <div className="alert alert-danger">No Quizzes are exists</div>
        :
        <div className="alert alert-warning">Loading</div>
      }
      </div>
    </TabPanel>
    <TabPanel value={value} index={1} dir={theme.direction}>
      <div className="row">
      {
        upcomingLoading ?
        upcomingQuizzes.length > 0 ?
        upcomingQuizzes.map((value , key) => (
          <div className="col col-md-4" key={key} style={{ marginBottom : 15 }}>
          <QuizzesCard caseCheck="#20c795" quiz={value} />
          </div>
        ))
        :
        <div className="alert alert-danger">No Quizzes are exists</div>
        :
        <div className="alert alert-warning">Loading</div>
      }
      </div>
    </TabPanel>
    <TabPanel value={value} index={2} dir={theme.direction}>
      <div className="row">
      {
        runningLoading ?
        runningQuizzes.length > 0 ?
        runningQuizzes.map((value , key) => (
          <div className="col col-md-4" key={key} style={{ marginBottom : 15 }}>
          <QuizzesCard caseCheck="#f59042" quiz={value} />
          </div>
        ))
        :
        <div className="alert alert-danger">No Quizzes are exists</div>
        :
        <div className="alert alert-warning">Loading</div>
      }
      </div>
    </TabPanel>
    <TabPanel value={value} index={3} dir={theme.direction}>
      <div className="row">
      {
        missedLoading ?
        missedQuizzes.length > 0 ?
        missedQuizzes.map((value , key) => (
          <div className="col col-md-4" key={key} style={{ marginBottom : 15 }}>
          <QuizzesCard caseCheck="#f54b42" quiz={value} />
          </div>
        ))
        :
        <div className="alert alert-danger">No Quizzes are exists</div>
        :
        <div className="alert alert-warning">Loading</div>
      }
      </div>
    </TabPanel>
  </SwipeableViews>
</div>
        }
</div>
       
        </div>
        :
        <></>
    )
}

export default MyQuizzes
