import { Route } from "react-router-dom";
import UsersCard from "../components/UsersCard";
import AddCourse from "../pages/AddCourse";
import AddDoctorToCourse from "../pages/AddDoctorToCourse";
import Courses from "../pages/Courses";
import Home from "../pages/Home";
import Info from "../pages/Info";
import Lectures from "../pages/Lectures";
import Login from "../pages/Login";
import MyQuizzes from "../pages/MyQuizzes";
import QuizPage from "../pages/QuizPage";
import Register from "../pages/Register";
import Table from "../pages/Table";

  export const routes = [
   {
    path : '/login',
    component : Login
   },
   {
    path : '/register',
    component : Register
},
{
  path : '/',
  component : Home,
  routes: [
    {
      path: "/",
      exact : true,
      component: Courses
    },
    {
      path: "/info",
      component: Info
    },
    {
      path: "/questions/:courseTitle/:lectureTitle",
      component: Table
    },
    {
      path: "/myCourse/:title",
      component: Lectures
    },
    {
      path: "/addDoctorToCourse",
      component: AddDoctorToCourse
    },
    {
      path: "/myQuizzes/:title",
      component: MyQuizzes
    },
    {
      path: "/addCourse",
      component: AddCourse
    },
    {
      path: "/quiz/:id",
      component: QuizPage
    },
    {
      path: "/addUsers",
      component: UsersCard
    }
  ]
}
  ];

  export function RouteWithSubRoutes(route) {
    return (
      <Route
        path={route.path}
        render={props => (
          // pass the sub-routes down to keep nesting
          <route.component {...props} routes={route.routes} />
        )}
      />
    );
  }