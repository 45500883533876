import React , { useState , useEffect, useRef } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import APIS from '../api/api';
import { useHistory, useParams } from 'react-router';
import TableComponent from '../components/TableComponent';
import * as XLSX from "xlsx";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { useSnackbar } from 'notistack';


const useStyles = makeStyles((theme) => ({
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    }
  }));

const Table = () => {

  const history = useHistory()

    const { enqueueSnackbar } = useSnackbar();

    const fileRef = useRef(null)

    const handleClickVariant = (variant , message) => {
        // variant could be success, error, warning, info, or default
        enqueueSnackbar(message, { variant });
      };

    const readExcel = (file) => {
        if(file.name){

        const promise = new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsArrayBuffer(file);
    
          fileReader.onload = (e) => {
            const bufferArray = e.target.result;
    
            const wb = XLSX.read(bufferArray, { type: "buffer" });
    
            const wsname = wb.SheetNames[0];
    
            const ws = wb.Sheets[wsname];
    
            const data = XLSX.utils.sheet_to_json(ws);
    
            resolve(data);
          };
    
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
    
        promise.then(async (d) => {
            handleClickVariant('success' , 'Questions is loading')
                await APIS.importQuestions(lectureTitle , d).then(res => {
                    fileRef.current.value = ""
                    setFile({})
                    handleClickVariant('success' , 'Questions uploaded')
                    setTimeout(() => {
                        window.location.reload()
                    } , 3000)
                }).catch(err => {console.log(err.response.data)})
        });
    }
    else
    handleClickVariant('error' , 'Upload file')
      };

    const classes = useStyles();
    const [myLecture , setMyLecture] = useState([])
    const [file , setFile] = useState({})
    const { courseTitle , lectureTitle } = useParams()
    const [loading , setLoading] = useState(false)
    const [loadingPage , setLoadingPage] = useState(false)

    useEffect(() => {
      
      const myProfileAPI = async () => {
        await APIS.myProfile().then(res => {
          if(Number(res.data.user_type_id) !== 0)
          setLoadingPage(true)
          else
          history.push('/')
        }).catch(err => history.push('/login'))
      }

      if(!localStorage.getItem('token')){
        history.push('/login')
    }
    else{
      myProfileAPI()
    }

      const lectureQuestionsAPI = async () => {
        await APIS.lectureQuestions(courseTitle , lectureTitle).then(res => {
            setMyLecture(res.data)
            if(!res.data.question_bank)
            handleClickVariant('error' , 'No Questions are exists')
            setLoading(true)
        }).catch(err => {
          handleClickVariant('error' , 'No Questions are exists')
        })
      }
      lectureQuestionsAPI()
    } , [courseTitle , lectureTitle , history])

    return (
      loadingPage ?
        <div>
<div className={classes.toolbar} />
<div className="row">
  <h1 style={{ fontWeight : 'bold' }}>Course: {courseTitle}</h1>
  <h3 style={{ fontWeight : 'bold' }}>Lecture: {lectureTitle}</h3>
{
    loading ?
    <>
    <div className="row" style={{ margin : 20 , display : 'flex' , justifyContent : 'right' , alignItems : 'center' }}>
    <input
        type="file"
        ref={fileRef}
        className="col-md-2 form-control"
        onChange={(e) => {
          setFile(e.target.files[0])
        }}
      />
      <button onClick={() => readExcel(file)} className="col-md-1 btn btn-primary" style={{ display : 'flex' , alignItems : 'center' , justifyContent : 'center' }}><i style={{ marginRight : 5 }}><CloudUploadIcon /></i> Upload</button>
    </div>
        <TableComponent rowsData={myLecture.question_bank || []} lectureTitle={lectureTitle} />
    </>
        :
        <></>
        }
</div>
       
        </div>
        :
        <></>
    )
}

export default Table
