import React from 'react'
import {
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import { RouteWithSubRoutes , routes } from './router/router'
import {SnackbarProvider} from 'notistack'
const App = () => {
  return (
    <Router>
      <SnackbarProvider maxSnack={3}>
    <Switch>
        {routes.map((route, i) => (
          <RouteWithSubRoutes key={i} {...route} />
        ))}
      </Switch>
      </SnackbarProvider>
    </Router>
  )
}

export default App