import React , { useState , useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import APIS from '../api/api';
import LecturesCard from '../components/LecturesCard';
import { useHistory, useParams } from 'react-router';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { Tooltip } from '@material-ui/core';
import { Modal } from 'react-bootstrap';
// import { Button } from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import dateFormat from 'dateformat'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles((theme) => ({
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    }
  }));
  

const Lectures = () => {
    const classes = useStyles();
    const [myLectures , setMyLectures] = useState([])
    const { title } = useParams()
    const [loading , setLoading] = useState(false)
    const [code , setCode] = useState('')
    const [message , setMessage] = useState('Click to copy!')
    const [error , setError] = useState('')
    const [loadingPage , setLoadingPage] = useState(false)
    const [modalShow, setModalShow] = useState(false);
    const [modalShowQuiz, setModalShowQuiz] = useState(false);
    const [startDate, setStartDate] = useState(dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss"));
    const [endDate, setEndDate] = useState(dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss"));
    const [lecturesId , setLecturesId] = useState([])
    const handleStartDateChange = (date) => {
      setStartDate(dateFormat(date, "yyyy-mm-dd HH:MM:ss"));
    };

    const handleEndDateChange = (date) => {
      setEndDate(dateFormat(date, "yyyy-mm-dd HH:MM:ss"));
    };

    const { enqueueSnackbar } = useSnackbar();
    const handleClickVariant = (variant , message) => {
      // variant could be success, error, warning, info, or default
      enqueueSnackbar(message, { variant });
    };

    const [name , setName] = useState('')
    const [totalQuestions , setTotalQuestions] = useState(10)
    const [description , setDescription] = useState('')
    const [quizName , setQuizName] = useState('')

    const createLectureAPI = async () => {
      const formData = new FormData()
      var format = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
      const checker = format.test(name)
      if(checker){
        handleClickVariant('error' , 'Name should not cotains special characters')
        return true;
      }
      formData.append('name' , name)
      formData.append('description' , description)
      await APIS.createLecture(title , formData).then(res => {
        handleClickVariant('success' , 'Lecture Created')
        setTimeout(() => {
          window.location.reload()
        } , 2000)
      }).catch(err => {
        handleClickVariant('error' , err.response.data[0].message)
      })
    }

    const generateQuizAPI = async () => {
      if(quizName === ''){
        handleClickVariant('error' , 'Quiz name is required')
      }
      else if(startDate >= endDate){
        handleClickVariant('error' , 'Start Date is equal or bigger than End Date')
      }
      else if (totalQuestions < 10){
        handleClickVariant('error' , 'Total Questions should be more than or equal 10')
      }
      else if (lecturesId.length === 0){
        handleClickVariant('error' , 'Choose Lectures')
      }
      else{
        console.log(startDate , endDate)
      const formData = new FormData()
      formData.append('start_date_time' , startDate)
      formData.append('end_date_time' , endDate)
      formData.append('lectures_id' , JSON.stringify(lecturesId))
      formData.append('totalQuestions' , totalQuestions)
      formData.append('course_id' , myLectures.id)
      formData.append('quiz_name' , quizName)
      handleClickVariant('warning' , 'Loading...')
      await APIS.generateQuiz(formData).then(res => {
        handleClickVariant('success' , 'Quiz Created')
        setTimeout(() => {
          window.location.reload()
        } , 2000)
      }).catch(err => {
        handleClickVariant('error' , err.response.data[0].message)
      })
      }
    }


    const history = useHistory()
    useEffect(() => {
      const myProfileAPI = async () => {
        await APIS.myProfile().then(res => {
          if(Number(res.data.user_type_id) !== 0)
          setLoadingPage(true)
          else
          history.push('/')
        }).catch(err => history.push('/login'))
      }

      if(!localStorage.getItem('token')){
        history.push('/login')
    }
    else{
      myProfileAPI()
    }
      const coursesCardAPI = async () => {
        await APIS.myLectures(title).then(res => {
            // console.log(res.data)
            if(!res.data.lectures)
            setError('No Lectures are exists')
            setMyLectures(res.data)
            setCode(res.data.code)
            setLoading(true)
        }).catch(err => {
          console.log(err.response.data)
        })
      }
      coursesCardAPI()
    } , [title , history])

    const changeCode = async () => {
      console.log(myLectures)
        await APIS.updateCode(myLectures.id).then(res => setCode(res.data.data.code)).catch(err => console.log(err.response.data))
    }

    const handleChangeSelect = (event , id) => {
      // setState({ ...state, [event.target.name]: event.target.checked });
      if(event.target.checked === true){
        setLecturesId([...lecturesId , id])
      }
      else{
        const newList = lecturesId.filter((item) => item !== id)
        setLecturesId(newList)
      }
    };

    return (
      loadingPage ?
        <div>
<div className={classes.toolbar} />
<div className="row">
  <h1 style={{ fontWeight : 'bold' }}>({myLectures.title}) Lectures</h1>
  <div className="col-md-12">
  <button className="form-control col-md-1" style={{ float : 'right' , marginLeft : 5 , marginTop : 5 }} onClick={() => changeCode()}>Change</button>
  <CopyToClipboard text={code} >
  <Tooltip title={message} onMouseDown={() => setMessage('Copied!')} onMouseUp={() => setMessage('Click to copy!')}>
  <input type="text" className="form-control col-md-4" style={{ float : 'right' , cursor : 'pointer' , marginLeft : 5 , marginTop : 5 }} value={code} readOnly />
  </Tooltip>
        </CopyToClipboard>
        <button className="btn btn-primary" onClick={() => setModalShow(true)} style={{ borderRadius : 50 }}>Add Lecture</button>
        {!error ? <button className="btn btn-danger" onClick={() => setModalShowQuiz(true)} style={{ borderRadius : 50 }}>Generate Quiz</button> : <></>}
        <Modal
show={modalShow}
onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add Lecture
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Name</label>
          <input className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
          <br></br>
          <label>Description</label>
          <input className="form-control" value={description} onChange={(e) => setDescription(e.target.value)} />
        </Modal.Body>
        <Modal.Footer>
        <button className="btn btn-success" onClick={() => createLectureAPI()}>Create</button>
          <button className="btn btn-primary" onClick={() => setModalShow(false)}>Close</button>
        </Modal.Footer>
      </Modal>

      <Modal
show={modalShowQuiz}
onHide={() => setModalShowQuiz(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Generate Quiz
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            
          {
            myLectures.lectures && myLectures.lectures.map((value , key)=> (
              value.status === '1' ?
              <div className="col col-md-4" key={key}>
                <FormControlLabel
        control={<Checkbox onChange={(e) => handleChangeSelect(e , value.id)} name={value.name} />}
        label={value.name}
      />
              </div>
              :
              <></>
            ))
          }
          </div>
          <br></br>
          <label>Quiz Name</label>
          <input className="form-control" type="text" min={10} value={quizName} onChange={(e) => setQuizName(e.target.value)} />
          <br></br>
          <br></br>
          <label>Total Questions</label>
          <input className="form-control" type="number" min={10} value={totalQuestions} onChange={(e) => setTotalQuestions(e.target.value)} />
          <br></br>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="row">
              <div className="col col-md-2">
          <label>Start Date Time</label>
          </div>
          <div className="col col-md-5">
          <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Date picker dialog"
          format="MM/dd/yyyy"
          value={startDate}
          onChange={handleStartDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        </div>
        <div className="col col-md-5">
          <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          label="Time picker"
          value={startDate}
          onChange={handleStartDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
        </div>


        <div className="col col-md-2">
          <label>End Date Time</label>
          </div>
          <div className="col col-md-5">
          <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Date picker dialog"
          format="MM/dd/yyyy"
          value={endDate}
          onChange={handleEndDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
        </div>
        <div className="col col-md-5">
          <KeyboardTimePicker
          margin="normal"
          id="time-picker"
          label="Time picker"
          value={endDate}
          onChange={handleEndDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change time',
          }}
        />
        </div>
        </div>
          </MuiPickersUtilsProvider>
        </Modal.Body>
        <Modal.Footer>
        <button className="btn btn-danger" onClick={() => generateQuizAPI()}>Generate</button>
          <button className="btn btn-primary" onClick={() => setModalShowQuiz(false)}>Close</button>
        </Modal.Footer>
      </Modal>

  </div>
{
    loading ?
    error ?
    <div className="row">
    <div className="alert-danger col-md-12" style={{ padding : 15 , marginTop : 10 , fontWeight : 'bold' }}>{error}</div>
    </div>
    :
          myLectures.lectures.map((value , key) => (
            <div className="col-md-3" style={{ marginTop : 10 ,  }} key={key}>
              <LecturesCard lecture={value} />
            </div>
          ))
          :
          <></>
        }
</div>
       
        </div>
        :
        <></>
    )
}

export default Lectures
