import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import './QuizComponent.css'
import APIS from '../api/api';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import dateFormat from 'dateformat';


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
}));

export default function QuizComponent({ questions , quiz }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [finalAnswers , setFinalAnswers] = useState([])
  const [profile , setProfile] = useState({})
  const [loadingPage , setLoadingPage] = useState(false)
  const [checkNext , setCheckNext] = useState(false) 
  const [loadingScreen , setLoadingScreen] = useState(false)
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar();
  const [checkerDate] = useState(dateFormat(new Date(), "yyyy-mm-dd HH:MM:ss"))
    useEffect(() => {
      if(quiz.start_date_time > checkerDate || quiz.end_date_time < checkerDate){
        window.location.href = "/"
      }
      else{
        setLoadingScreen(true)
      }
    } , [])


  const steps = questions;

  const handleClickVariant = (variant , message) => {
    // variant could be success, error, warning, info, or default
    enqueueSnackbar(message, { variant });
  };

  const handleNext = async () => {
    if(activeStep === questions.length - 1){
        let sumDegree = 0
        let totalDegree = 0
        for(let i = 0 ; i < questions.length ; i++){
            totalDegree += Number(questions[i].question_degree)
            if(questions[i].true_answer === finalAnswers[i])
            sumDegree += Number(questions[i].question_degree)
        }
        let studentDegree = {
            sumDegree : sumDegree,
            totalDegree : totalDegree
        }

        const formData = new FormData()
        formData.append('student_degree' , JSON.stringify(studentDegree))
        formData.append('quiz_id' , quiz.id)
        formData.append('student_id' , profile.id)

        await APIS.storeResult(formData).then(res => {
            handleClickVariant('success' , 'Quiz sent successfully')
            setTimeout(() => {
                window.location.href = "/"
            } , 3000)
        }).catch(err => handleClickVariant('error' , 'There is something error has happened'))
    }
    else{
    if(checkNext){
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setCheckNext(false)
    }
    else{
        handleClickVariant('error' , 'Please Select Answer')
    }
    }
  };

  useEffect(() => {
    const myProfileAPI = async () => {
      await APIS.myProfile().then(res => {
        if(Number(res.data.user_type_id) === 0){
        setProfile(res.data)
        setLoadingPage(true)
        }
        else
        history.push('/')
      }).catch(err => history.push('/login'))
    }

    if(!localStorage.getItem('token')){
      history.push('/login')
  }
  else{
    myProfileAPI()
  }
  } , [history])

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

//   const handleReset = () => {
//     setActiveStep(0);
//   };

  function getStepContent(stepIndex) {
    return (questions.map((value , key) => {
        if(key === stepIndex){
            var answers = value.answers.replace(/'/g, '"');
            var parsedAnswers = JSON.parse(answers)
            var id = value.id
            return (
                <div key={key}>
                    <h3 style={{ fontWeight : 'bold' }}>{ key + 1 } - {value.question}</h3>
                    <div className="row" style={{ margin : 50 }}>
                        {

                            parsedAnswers.map((val , k) => (
                                <div className="col col-md-6" key={k}>
                                    <label className="container" style={{ cursor : 'pointer' }}> <h3 style={{ fontWeight : 'bold' , paddingLeft : 30 }}>{val}</h3>
  <input type="radio" className="quiz" name={value.id} value={val} onChange={(e) => {
      finalAnswers[stepIndex] = e.target.value
      setCheckNext(true)
}}  />
  <span className="checkmark"></span>
</label>
                                   
                        </div>
                            ))
                        }
                    </div>
                </div>
            )
        }
    }))
  }

  return (
      loadingPage && loadingScreen ?
    <div className={classes.root}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((value , key) => (
          <Step key={key}>
            <StepLabel></StepLabel>
          </Step>
        ))}
      </Stepper>
      <div>
        {activeStep === steps.length ? (
          <div>
            <Typography className={classes.instructions}>All steps completed</Typography>
            {/* <Button onClick={handleReset}>Reset</Button> */}
          </div>
        ) : (
          <div>
              <br></br>
            <div style={{ backgroundColor : 'white' , padding : 20 }}>
            <Typography className={classes.instructions}>{getStepContent(activeStep)}</Typography>
            <Button
                disabled={activeStep === 0}
                onClick={handleBack}
                variant="contained"
                color="secondary"
                className={classes.backButton}
              >
                Back
              </Button>
              <Button variant="contained" color="primary" onClick={handleNext}>
                {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
    :
    <></>
  );
}
